import { render, staticRenderFns } from "./CourseContent.vue?vue&type=template&id=e1d33078&scoped=true"
import script from "./CourseContent.vue?vue&type=script&lang=js"
export * from "./CourseContent.vue?vue&type=script&lang=js"
import style0 from "./CourseContent.vue?vue&type=style&index=0&id=e1d33078&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e1d33078",
  null
  
)

export default component.exports